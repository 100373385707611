import {Age} from "@sparkie/shared-model/src/model/common/Age";

export class ArpAgeFormatValueConverter {

    toView(value, empty='Unknown') {

        if (value) {
            return Age.formatAsAge(value)
        } else {
            return empty;
        }
    }
}
