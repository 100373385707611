import { bindable, bindingMode, computedFrom, customElement, inject } from "aurelia-framework";

@customElement("arp-password-input")
@inject(Element)
export class ArpPasswordInputElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) test_id: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) placeholder: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) autocomplete: string;

    private element: Element;
    private showPassword: boolean = false;

    constructor(element) {
        this.element = element;
    }

    bind() {
        // Find the input and set the data-test-id attribute
        const input = this.element.querySelector("input");
        if (input) {
            input.setAttribute("data-test-id", this.test_id);
            input.setAttribute("placeholder", this.placeholder);
            input.setAttribute("autocomplete", this.autocomplete);
        }
    }

    @computedFrom("showPassword")
    get iconClass() {
        return this.showPassword
            ? "glyphicon glyphicon-eye-close form-control-feedback"
            : "glyphicon glyphicon-eye-open form-control-feedback";
    }

    @computedFrom("showPassword")
    get inputType() {
        return this.showPassword ? "text" : "password";
    }

    togglePassword() {
        this.showPassword = !this.showPassword;
    }
}
